<template>
  <router-view></router-view>
</template>

<script setup>

import {inject, onMounted} from "vue";
import {useRoute} from "vue-router";
import {userStore} from "@/store/user";
const changeStatus = inject('changeStatus')
const user = userStore();
const route = useRoute()
const id = route.params.id;
user.appID = id;

onMounted(() => {
  user.getQuestion(changeStatus)
})
</script>
<style scoped></style>